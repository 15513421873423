import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";
import {
  FcAddImage,
  FcAddressBook,
  FcBusinessman,
  FcFilingCabinet,
  FcMoneyTransfer,
  FcParallelTasks,
  FcStackOfPhotos,
} from "react-icons/fc";
import { GiCrossedChains, GiIndianPalace } from "react-icons/gi";
import { GrMultiple } from "react-icons/gr";
import { ImUpload } from "react-icons/im";
import {
  MdAdminPanelSettings,
  MdBadge,
  MdNotificationAdd,
} from "react-icons/md";
import { RiHandCoinLine } from "react-icons/ri";
import List from "./List";

const Sidebar = ({ isOpen, onOpen, onClose }) => {
  const data = [
    { name: "DashBoard", icon: <FcFilingCabinet />, link: "/" },
    { name: "Subcription", icon: <MdBadge />, link: "/subscribtion" },
    { name: "Videos", link: "/movies", icon: <FcAddImage /> },
    { name: "Series", link: "/series", icon: <GrMultiple /> },
    {
      name: "Categories",
      link: "/categories",
      icon: <FcParallelTasks />,
    },
    {
      name: "Banner",
      link: "/banner",
      icon: <FcStackOfPhotos />,
    },
    {
      name: "Users",
      link: "/users",
      icon: <FcBusinessman />,
    },
    {
      name: "Notifications",
      link: "/notification",
      icon: <MdNotificationAdd />,
    },
    {
      name: "Admin",
      link: "/admins",
      icon: <MdAdminPanelSettings />,
    },
    {
      name: "Genres",
      link: "/genres",
      icon: <FcAddressBook />,
    },
    {
      name: "Redeem Request",
      link: "/redeem-request",
      icon: <RiHandCoinLine />,
    },
    {
      name: "Upload Request",
      link: "/upload-request",
      icon: <ImUpload />,
    },
    {
      name: "Poster",
      link: "/poster",
      icon: <FcStackOfPhotos />,
    },
    {
      name: "Tangent Career",
      link: "/indian-icon",
      icon: <GiIndianPalace />,
    },
    {
      name: "Sub Category",
      link: "/sub-categories",
      icon: <FcParallelTasks />,
    },
    {
      name: "Tangent Career Category",
      link: "/tangent-career-category",
      icon: <FcParallelTasks />,
    },
    {
      name: "Purchase Records",
      link: "/purchase-records",
      icon: <FcMoneyTransfer />,
    },
    {
      name: "Referral Chain",
      link: "/chain",
      icon: <GiCrossedChains />,
    },
    {
      name: "Sales",
      link: "/sales",
      icon: <FcMoneyTransfer />,
    },
  ];
  return (
    <Box
      borderRight={{
        base: "none",
        md: "1px solid #eee",
      }}
      h="100%"
      sx={{
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <List data={data} />
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"} placement={"left"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Tangentweb`}</DrawerHeader>
          <DrawerBody>
            <List data={data} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
