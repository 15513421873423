import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

const SalesBox = ({ title, amount, onClick, isActive }) => {
  return (
    <Box
      p={6}
      bg={isActive ? "blue.500" : "white"}
      color={isActive ? "white" : "black"}
      borderRadius="lg"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
      cursor="pointer"
      onClick={onClick}
      _hover={{ transform: "translateY(-2px)", transition: "all 0.2s" }}
    >
      <Text fontSize="lg" fontWeight="medium">
        {title}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" mt={2}>
        ₹{amount}
      </Text>
    </Box>
  );
};

const Sales = () => {
  const [salesData, setSalesData] = useState(null);
  const [activeBox, setActiveBox] = useState("today");

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get(
          "https://api.tangentweb.in/admin/sales"
        );
        setSalesData(response.data);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };
    fetchSales();
  }, []);

  const getActiveData = () => {
    if (!salesData) return [];
    switch (activeBox) {
      case "today":
        return salesData.todaySale || [];
      case "week":
        return salesData.lastSevenDaysSale || [];
      case "month":
        return salesData.lastThirtyDaysSale || [];
      default:
        return [];
    }
  };

  return (
    <Box p={8}>
      <Box
        mb={8}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading>Sales Overview</Heading>
        <CSVLink
          filename={`Sales-${activeBox}-${
            new Date().toISOString().split("T")[0]
          }.csv`}
          data={getActiveData().map((sale) => ({
            Name: sale.name,
            Phone: sale.phoneNumber,
            Email: sale.email || "-",
            "Subscription Type": sale.subscriptionType?.title || "-",
            Amount: sale.subscriptionType?.price || 0,
            "Valid Until": new Date(
              sale.subscriptionExpireDate
            ).toLocaleDateString(),
          }))}
          target="_blank"
        >
          <Button colorScheme="blue">Download</Button>
        </CSVLink>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
        <SalesBox
          title="Today's Sales"
          amount={salesData?.todaySaleAmount || 0}
          onClick={() => setActiveBox("today")}
          isActive={activeBox === "today"}
        />
        <SalesBox
          title="Last 7 Days Sales"
          amount={salesData?.lastSevenDaysSaleAmount || 0}
          onClick={() => setActiveBox("week")}
          isActive={activeBox === "week"}
        />
        <SalesBox
          title="Last 30 Days Sales"
          amount={salesData?.lastThirtyDaysSaleAmount || 0}
          onClick={() => setActiveBox("month")}
          isActive={activeBox === "month"}
        />
      </SimpleGrid>

      <Box bg="white" borderRadius="lg" overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Subscription Type</Th>
              <Th>Amount</Th>
              <Th>Valid Until</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getActiveData().map((sale) => (
              <Tr key={sale._id}>
                <Td>{sale.name}</Td>
                <Td>{sale.phoneNumber}</Td>
                <Td>{sale.email || "-"}</Td>
                <Td>{sale.subscriptionType?.title || "-"}</Td>
                <Td>₹{sale.subscriptionType?.price || 0}</Td>
                <Td>
                  {new Date(sale.subscriptionExpireDate).toLocaleDateString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Sales;
